import styled from 'styled-components';
import { Image, Heading } from '@nintendo-of-america/component-library';

export const StyledHeading = styled(Heading)`
  grid-column: 1 / span 2;

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-column: 1 / span 4;
  }
`;

export const CharacterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CharacterAnchor = styled.a`
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    ${({ theme }) => theme.spacing[16]}
  }
`;

export const CharacterBg = styled(Image)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.15);
  transition: ${({ theme }) =>
    `transform ${theme.movement.duration} ${theme.movement.easing}`};

  ${CharacterAnchor}:hover & {
    transform: scale(1);
  }
`;

export const CharacterAsset = styled(Image)`
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ${({ theme }) =>
    `transform ${theme.movement.duration} ${theme.movement.easing}`};

  ${CharacterAnchor}:hover & {
    transform: scale(1.15);
  }
`;
