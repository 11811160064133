import React from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import {
  Button,
  Heading,
  NewsIcon,
} from '@nintendo-of-america/component-library';
import { NewsArticles } from '@local/components';
import { Grid } from '@shared/ui';

const News = ({ articles }) => {
  const { text } = useLocalizer();
  const { locale } = useRouter();

  return (
    <Grid columns={1} gap={24} desktop={{ gap: 32, justifyItems: 'start' }}>
      <Heading variant="h1">{text('News')}</Heading>
      <Heading.NewLevel>
        <NewsArticles articles={articles} />
      </Heading.NewLevel>
      <Button icon={NewsIcon} href="/whatsnew" locale={locale}>
        {text('See all news articles')}
      </Button>
    </Grid>
  );
};

News.propTypes = {
  articles: NewsArticles.propTypes.articles,
};

export default News;
