import { useCallback } from 'react';
import { shape } from 'prop-types';
import { getArticles, getBestSellers } from '@local/lib/api';
import { useLocalizer } from '@nintendo-of-america/react-hooks/';
import {
  Heading,
  ScreenReaderOnly,
  Spacer,
} from '@nintendo-of-america/component-library';
import {
  PromoBladeA,
  PromoBladeB,
  Hero,
  Section,
  PromoCCollection,
  ProductCollections,
  RecentlyViewed,
  RichText,
  CenterStage,
} from '@local/components';
import { Characters, News } from '@local/components/pages/Homepage';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { useRestoreScroll } from '@local/lib/hooks';
import { homePageLocaleAlternates } from '@local/lib/helpers/hreflang';
import getPageBySlug from '@local/lib/contentful/api/getPageBySlug';
import {
  PAGE_TYPES,
  SYNDICATION,
} from '@nintendo-of-america/contentful-api/constants';
import getPageProps from '@local/lib/api/getPageProps';
import { homePagePropTypes } from '@nintendo-of-america/contentful-api/parsers';
import { PLATFORM_COLOR } from '@local/lib/constants';
import { getGraph } from '@nintendo-of-america/graph-api';

export async function getServerSideProps(context) {
  const { res, locale } = context;

  const graph = getGraph(context);
  const page = await getPageBySlug({
    slug: `/`,
    locale,
    pageContentType: PAGE_TYPES.HOMEPAGE,
    graph,
  });
  if (!page) return { notFound: true };

  const parsedBestSellers = await getBestSellers({ graph, locale, limit: 16 });

  const { data: articles } = await getArticles({
    graph,
    locale,
    res,
    limit: 6,
    tags: [SYNDICATION.NCOM],
  });

  return getPageProps({
    page: {
      ...page,
      latestArticles: articles?.collection.items || null,
      bestSellers: parsedBestSellers,
    },
    analytics: {
      pageType: 'Merchandising',
      pageName: 'Home',
      pageCategory: 'Home',
    },
    res,
    localeAlternates: homePageLocaleAlternates,
  });
}

function Home({ page }) {
  const { text } = useLocalizer();

  const {
    content: {
      characters,
      promoRail,
      featuredRailA,
      featuredRailB,
      centerStage,
      centerStageDarkMode,
      secondaryCenterStage,
      promoA,
      promoB,
      promoC,
      legal,
    },
    bestSellers,
    latestArticles,
  } = page;

  const isDesktop = useMediaWidth(MEDIA_TYPE.TABLET);

  const { waitForElementRef } = useRestoreScroll();

  const handleContentMounted = useCallback(() => {
    waitForElementRef(true);
  }, [waitForElementRef]);

  const hasSecondaryHero = secondaryCenterStage.CONTENT_TYPE;
  const bestSellersRail = {
    heading: text('Digital best sellers'),
    cta: {
      label: text('See full list'),
      url: '/store/games/best-sellers/',
    },
    list: bestSellers,
  };

  return (
    <>
      <ScreenReaderOnly as="h1">{text('Nintendo.com home')}</ScreenReaderOnly>
      <Heading.NewLevel>
        <CenterStage
          centerStage={centerStage}
          centerStageDarkMode={centerStageDarkMode}
          isDesktop={isDesktop}
        />
        <Section constrained={hasSecondaryHero} small={hasSecondaryHero}>
          {hasSecondaryHero && (
            <>
              <Heading variant="h1" as="h2">
                {promoRail.heading}
              </Heading>
              <Spacer size={16} />
              <Hero
                secondaryHero
                isDesktop={isDesktop}
                hero={{
                  assetPath: {
                    layout: 'constrain',
                    desktop: secondaryCenterStage?.asset.secondary.assetPath,
                    mobile: secondaryCenterStage?.asset.primary.assetPath,
                    alt: secondaryCenterStage?.asset.alt,
                  },
                  assetDimensions: {
                    desktop: secondaryCenterStage?.asset.secondary.dimensions,
                    mobile: secondaryCenterStage?.asset.primary.dimensions,
                  },
                  description: {
                    text: secondaryCenterStage?.heading,
                    desktopTextColor: 'dark',
                  },
                  cta: {
                    url: secondaryCenterStage?.cta.url,
                    name: secondaryCenterStage?.cta.label,
                  },
                  contentRating: secondaryCenterStage?.contentRating,
                  contentDescriptors: secondaryCenterStage?.contentDescriptors,
                  gamesShown: secondaryCenterStage?.gamesShown,
                }}
              />
            </>
          )}
          <PromoCCollection
            contained
            collection={{
              heading: !hasSecondaryHero && promoRail.heading,
              productList: promoRail?.list?.map(
                ({
                  asset,
                  assetAlt,
                  url,
                  heading,
                  tag,
                  descriptionTag,
                  platformLabel,
                  descriptionTagColor,
                }) => ({
                  artPath: asset,
                  keyArtAlt: assetAlt,
                  contentLink: url,
                  contentTitle: heading,
                  productPlatform: text(platformLabel) || tag,
                  platformColor:
                    platformLabel || tag
                      ? PLATFORM_COLOR[
                          String(platformLabel || tag)
                            .toUpperCase()
                            .replace(/ /g, '_')
                        ]
                      : null,
                  descriptionTag: text(descriptionTag),
                  descriptionTagColor,
                })
              ),
            }}
          />
        </Section>
        <Section constrained small divider={['top']}>
          <PromoBladeA
            isDesktop={isDesktop}
            promo={{
              asset: promoA?.asset,
              heading: promoA?.heading,
              description: promoA?.description,
              cta: {
                name: promoA?.ctaList[0]?.label,
                url: promoA?.ctaList[0]?.url || '',
              },
              bgImage: {
                image: {
                  url: promoA?.background.image,
                },
              },
            }}
          />
        </Section>
        <Section constrained small divider={['top']}>
          <PromoBladeB
            promo={{
              heading: promoB?.heading,
              image: {
                url: promoB?.asset.primary.assetPath,
                alt: promoB?.asset.alt,
              },
              description: promoB?.description,
              cta: {
                name: promoB?.ctaList[0]?.label,
                url: promoB?.ctaList[0]?.url || '',
                showMSRP: false,
              },
            }}
          />
        </Section>
        {latestArticles?.length > 0 && (
          <Section constrained small divider={['top']}>
            <News articles={latestArticles} />
          </Section>
        )}
        {characters?.list?.length ? (
          <Section constrained small divider={['top', 'bottom']}>
            <Characters
              characters={characters?.list?.map(
                ({ asset, background, heading, url }) => ({
                  alt: asset.alt,
                  backgroundImage: background.image,
                  characterImage: asset.primary.assetPath,
                  name: heading,
                  url,
                })
              )}
            />
          </Section>
        ) : null}
        {promoC && !promoC?.hide ? (
          <Section constrained small divider={['top', 'bottom']}>
            <PromoBladeB
              promo={{
                heading: promoC.modules[0].heading,
                image: {
                  url: promoC.modules[0].asset.primary.assetPath || '',
                  alt: promoC.modules[0].asset.alt,
                },
                description: promoC.modules[0].body,
                cta: {
                  name: promoC.modules[0].cta.label,
                  url: promoC.modules[0].cta.url || '',
                  showMSRP: false,
                },
              }}
            />
          </Section>
        ) : null}
        <Section small constrained>
          <ProductCollections
            collections={[
              featuredRailA?.list?.length > 0 ? featuredRailA : bestSellersRail,
              featuredRailB,
            ].map((item) => ({
              heading: item.heading,
              cta: { fields: { ...item.cta } },
              products: item.list,
            }))}
          />
        </Section>
        <RecentlyViewed onProductsLoaded={handleContentMounted} small />
        <Section small constrained marginBottom={0} marginTop={0}>
          <RichText data={legal} textVariant="legal" />
        </Section>
      </Heading.NewLevel>
    </>
  );
}

Home.propTypes = {
  page: shape({
    content: homePagePropTypes,
    latestArticles: News.propTypes.articles,
  }),
};

export default Home;
