import { arrayOf, shape, string } from 'prop-types';
import { Link } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Heading } from '@nintendo-of-america/component-library';
import { Grid } from '@local/components';

import * as S from './Characters.styles';

function Characters({ characters }) {
  const { text } = useLocalizer();

  const renderCharacters = characters.map((character, index) => {
    const { alt, backgroundImage, characterImage, name, url } = character;

    return (
      <S.CharacterWrapper key={index}>
        <Link href={url}>
          <S.CharacterAnchor aria-label={alt}>
            <S.CharacterBg assetPath={backgroundImage} alt="" />
            <S.CharacterAsset assetPath={characterImage} alt={alt} />
          </S.CharacterAnchor>
        </Link>
        <Heading variant="h2">{text(name)}</Heading>
      </S.CharacterWrapper>
    );
  });

  return (
    <Grid columnsMobile={2} columns={4} gap={'1rem'}>
      <S.StyledHeading variant="h1">{text('Characters')}</S.StyledHeading>
      <Heading.NewLevel>{renderCharacters}</Heading.NewLevel>
    </Grid>
  );
}

Characters.propTypes = {
  characters: arrayOf(
    shape({
      backgroundImage: string,
      characterImage: string,
      name: string,
      url: string,
    })
  ),
};

export default Characters;
